footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
  margin: 2rem 0rem;
  opacity: 70%;
  height: 8rem;
}

footer p {
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
}

.easter-egg {
  font-size: 0;
  line-height: 1.5;
}

.easter-egg span {
  color: #f35154;
  font-size: 1rem;
  font-family: 'Lexend', sans-serif;
  font-weight: 700;
  display: inline-block;
  animation: move 10s ease-in-out infinite;
}

@keyframes move {
  0% {
    transform: translate(-30%, 0);
  }
  50% {
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
  }
  100% {
    transform: translate(30%, 0);
  }
}

.easter-egg span:nth-child(2) {
  animation-delay: 0.5s;
}

.easter-egg span:nth-child(3) {
  animation-delay: 1s;
}

.easter-egg span:nth-child(4) {
  animation-delay: 1.5s;
}

.easter-egg span:nth-child(5) {
  animation-delay: 2s;
}

.easter-egg span:nth-child(6) {
  animation-delay: 2.5s;
}

.easter-egg span:nth-child(7) {
  animation-delay: 3s;
}

.disable-highlighting {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}