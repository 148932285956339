.event-form-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0rem;
    margin-bottom: 4rem;
}
.create-form-header {
  padding-top: 2rem;
  margin-bottom: 1rem;
  white-space: nowrap;
  color: #F4FFFD;
}

.event-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

textarea {
  background-color: transparent;
  border-style: solid;
  border-color: #F4FFFD;
  border-radius: 3rem;
  height: 2.25rem;
  width: 16rem;
  margin: 0.25rem 0rem;
  padding-left: 0.75rem;
  font-family: 'Nunito', sans-serif;
  color: #F4FFFD;
  border-width: 2px;
  line-height: 2;
}


select {
  border-width: 2px;
}

.form-submit {
  width: 12rem;
  height: 2.25rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  align-self: center;
}

input[type="date"] {
  width: 12.5rem;
  font-size: 0.75rem;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: url(../../../public/static/cal.svg) no-repeat;
  width: 20px;
  height: 20px;
  /* padding-top: 0.25rem; */
  margin-top: 0.2rem;
  margin-right: 0.5rem;
}

input[type='time'] {
  width: 12.5rem;
  font-size: 0.75rem;
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: url(../../../public/static/time.svg) no-repeat;
  width: 20px;
  height: 20px;
  /* padding-top: 0.25rem; */
  margin-top: 0.25rem;
  margin-right: 0.2rem;
}

.date-time-input-container {
  display: flex;
  gap: 0.5rem;
}

input[type='number'] {
  font-size: 1rem;
  margin-top: 0.25rem;
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {
  margin-right: 0.75rem;
}

.location-result {
  color: #F4FFFD;
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  /* max-width: 80%; */
  overflow-x: hidden;
}

.location-result-container {
  /* max-width: 70%; */
}


.create-event {
  overflow-x: hidden;
  max-width: 100%;
}

.field-container-create-event {
  display: flex;
  flex-direction: column;
  margin: 0.25rem 0rem;
  max-width: 100%;
  /* align-items: center; */
}

.location-result-container {
  /* width: 100%; Set the width to 100% */
  /* max-width: 400px; Set a maximum width for better readability */
}

.location-result {
  color: #F4FFFD;
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  padding: 0.5rem; /* Add some padding for better spacing */
  border: 1px solid #F4FFFD; /* Add a border for better separation */
  cursor: pointer; /* Add a pointer cursor to indicate interactivity */
  max-width: 26rem;
}


