.edit-form-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem 0rem;
}

.field-container-update-event {
    display: flex;
    flex-direction: row;
    margin: 0.25rem 0rem;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .field-container-update-event button {
    flex: 1; 
    margin: .25rem;
    width: 7.75rem;
  }

#event-delete {
    display: flex;
    flex-direction: row;
    margin: 0.25rem 0rem;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

#delete-event {
  width: 7.75rem;
}