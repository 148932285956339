/* TODO Add site wide styles */

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;700&family=Nunito:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
}

html {
  background: radial-gradient(circle, rgba(81,187,254,1) 0%, rgba(250,76,76,1) 100%);
  scroll-behavior: smooth;
}

h1 {
  font-family: 'Lexend', sans-serif;
  font-weight: 700;
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
  font-family: 'Lexend', sans-serif;
}

h3 {
  font-size: 1rem;
  font-family: 'Lexend', sans-serif;
}

p {
  font-size: 1rem;
  font-family: 'Nunito';
  font-weight: 300;
}

span {
  font-size: 0.75rem;
  font-family: 'Nunito';
  font-weight: 300;
}

footer {
  font-size: 1rem;
  font-family: 'Nunito';
  font-weight: 300;
}

button {
  width: 6rem;
  height: 2rem;
  border-radius: 3rem;
  border-style: none;
  cursor: pointer;
  /* border-color: #F4FFFD; */
  /* border-width: 2px; */
  /* background: linear-gradient(145deg, rgba(250,130,76,1) 0%, rgba(255,89,100,1) 100%); */
  color: #F4FFFD;
  font-family: 'Lexend';
  transition: box-shadow 0.3s ease-in-out;
}

button:hover {
  box-shadow: 6px 2px 28px -5px rgba(0,0,0,0.51);
  -webkit-box-shadow: 6px 2px 28px -5px rgba(0,0,0,0.51);
  -moz-box-shadow: 6px 2px 28px -5px rgba(0,0,0,0.51);
}

form .errors {
  color: #FF5964;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  margin-left: 0.25rem;
}

a {
  color: white;
  cursor: pointer; 
  text-decoration: none;
}

.styled-link a {
  background:
    linear-gradient(
      to right,
      rgba(81, 187, 254, 0),
      rgba(81, 187, 254, 0)
    ),
    linear-gradient(
      to right,
      rgba(81, 187, 254, 1),
      rgba(137, 252, 0, 1),
      rgba(255, 0, 180, 1),
      rgba(255, 89, 100, 1),
      rgba(250, 130, 76, 1)
  );
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 200ms;
}

.styled-link :hover {
  background-size: 0 3px, 100% 3px;
}