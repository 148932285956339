/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  background: linear-gradient(145deg, rgba(250,130,76,0.75) 0%, rgba(255,89,100,0.75) 100%);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: #F4FFFD;
}
