.video-background {
  position: relative;
  width: 100vw;
  height: 100vh;
  /* height: 32rem; */
  /* overflow: hidden; */
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  /* width: auto; */
  /* height: auto; */
  z-index: -1;
  overflow: hidden;
}

.video-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #f4FFFD;
  /* margin: 2rem; */
  overflow: hidden;
}

.video-overlay h1 {
  font-size: 4.25rem;
  /* margin-bottom: 0.25rem; */
  /* margin-left: 1rem; */
}

.video-overlay h3 {
  font-weight: 400;
  font-size: 1.25rem;
}

.video-overlay p {
  font-family: 'Lexend', sans-serif;
  font-weight: 300;
}

.section-container {
  width: 100%;
  height: 28rem;
  /* Temp Border For Positioning */
  border-style: none;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}  

.section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.section-content h1 {
  color: #f4FFFD;
  margin-bottom: 1rem;
}

.animate-characters
{
  background-image: linear-gradient(
    -225deg,
    #89fc00 0%,
    #fa824c 29%,
    #ff5964 67%,
    #51bbfe 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 3rem;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.pin {
  width: 2rem;
  height: 2rem;
}



* {
  box-sizing: border-box;
  &::before, &::after {
    box-sizing: border-box;
  }
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-size: inherit;
  font-family: 'Lexend', sans-serif;
  &.learn-more {
    margin-top: 2rem;
    width: 10rem;
    font-weight: 700;
    color: #1d1d1d;
    background: #fff0f0;
    border: 2px solid #b18597;
    border-radius: 0.5rem;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #f9c4d2;
      border-radius: inherit;
      box-shadow: 0 0 0 2px #b18597, 0 0.225em 0 0 #ffe3e2;
      transform: translate3d(0, 0.25em, -1em);
      transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
    }
    &:hover {
      background: #ffe9e9;
      transform: translate(0, 0.25em);
      &::before {
        box-shadow: 0 0 0 2px #b18597, 0 0.25em 0 0 #ffe3e2;
        transform: translate3d(0, 0.5em, -0.5em);
      }
    }
    &:active {
      background: #ffe9e9;
      transform: translate(0em, 0.75em);
      &::before {
        box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2;
        transform: translate3d(0, 0, -1em);
      }
    }
  }
}