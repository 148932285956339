.login-form-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
}

.login-form-header {
  margin-bottom: 1rem;
  white-space: nowrap;
}

.login-form-header h2 {
  color: #F4FFFD;
  font-size: 2rem;
  text-align: center;
}

.input-field {
  background-color: transparent;
  border-style: solid;
  border-color: #F4FFFD;
  border-radius: 3rem;
  height: 2.25rem;
  width: 26rem;
  margin: 0.25rem 0rem;
  /* padding-inline-start: 0.75rem; */
  padding-left: 0.75rem;
  font-family: 'Nunito', sans-serif;
  color: #F4FFFD;
}

.login-form input[type='submit'].submit-field {
  /* background-color: transparent; */
  background: linear-gradient(145deg, rgba(250,130,76,1) 0%, rgba(255,89,100,1) 100%);
  border-style: none;
  border-radius: 3rem;
  width: 8rem;
  margin: 0.5rem 0rem;
  /* padding-inline-start: 0.75rem; */
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
  color: #F4FFFD;
  padding: 0.5rem;
  transition: all 0.2s ease-in-out;
}

input::placeholder {
  color: #F4FFFD;
  opacity: 60%;
}

.login-form input[type='submit'].submit-field:hover {
  background: linear-gradient(145deg, rgba(255,89,100,1) 0%, rgba(250,130,76,1) 100%);
  cursor: pointer;
  box-shadow: 6px 2px 28px -5px rgba(0,0,0,0.51);
  -webkit-box-shadow: 6px 2px 28px -5px rgba(0,0,0,0.51);
  -moz-box-shadow: 6px 2px 28px -5px rgba(0,0,0,0.51);
}

.field-label {
  font-size: 0.75rem;
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
  margin-left: 0.25rem;
  margin-top: 0.5rem;
  color: #F4FFFD;
  max-width: 60%;
}

.errors {
  color: white !important;
}