/* Main Containers ------------- */

.event-show-parent-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    padding-top: 4rem;
}

.event-show-banner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 65%;
    /* border-radius: 3rem; */
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0; /* Remove padding */
    height: 24rem;
}

.event-show-content-container {
    display: flex;
    flex-direction: column;
    width: 65%;
    /* background-color: rgba(230, 230, 230, 1); */
    background-color: rgba(244, 255, 253, 0.8);
    padding: 2rem;
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
}

.event-show-detail-container {
    margin-bottom: -3rem;
}

.event-show-detail-container p {
    color: #1D1D1D;
}

.event-show-map-container {
    width: 100%;
}

/* ----------------------------- */

.event-name-container {
    color: #1D1D1D;
    padding: 1rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

#event-edit-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 6rem;
    height: 2rem;
    border-radius: 3rem;
    border-style: none;
    background: linear-gradient(145deg, rgba(250,130,76,1) 0%, rgba(255,89,100,1) 100%);
    cursor: pointer;
    margin-left: 18.75rem;
    margin-top: -21.25rem;
}

#event-edit {
    font-family: 'Lexend', sans-serif;
    font-weight: 600;
    width: 100%;
    z-index: 1;
    color: white;
}

.detail-container-row-one {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 6rem;
    /* height: 14rem; */
}

.detail-event-inner-left {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-right: 1rem;
}

.detail-event-info {
    width: 95%;
    margin-left: 1rem;
    margin-bottom: 2rem;
    margin-right: 1rem;
}

.detail-event-info a {
    color: #1D1D1D;
    font-size: 1rem;
    font-weight: 700;
    transition: color, 0.3s ease;
}

.detail-event-info a:hover {
    color: #FF5964;
    font-weight: 700;
    text-decoration: underline;
}

.detail-event-info .desc-row {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1rem;
}

.detail-attendees-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.detail-attendees-info p {
    color: #1D1D1D;
}

.join-event {
    display: flex;
}

.join-event button {
    width: 12rem;
    height: 2rem;
    margin: 0.25rem;
    border-radius: 3rem;
    border-style: solid;
    border-color: #F4FFFD;
    border-width: 3px;
    background-color: transparent;
    color: #F4FFFD;
    transition: border-color 0.3s ease, color 0.3s ease;
    font-family: 'Lexend';
}

.join-event button:hover {
    border-color: green;
    box-shadow: none;
}


.attendee-circle {
    display: inline-block;
    width: 2.5rem; 
    height: 2.5rem; 
    border-radius: 50%;
    background-color: #F4FFFD;
    margin-right: 1rem;
}

.attendee-circle:hover {
    transform: scale(1.2); 
    transition: transform 0.2s ease;
}

.attendee-circle:hover::before {
    content: attr(data-name);
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    color: #F4FFFD;
    padding: .25rem;
    border-radius: .25rem;
    font-size: 1rem;
    bottom: 2.75rem;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

#event-large-map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

#event-large-map-container img {
    border-radius: 25px;
    height: auto;
}
.event-banner-profile-link {
    color: #1d1d1d;
    font-weight: 400;
}
.event-banner-profile-link a {
    color: #1d1d1d;
    font-size: 1rem;
    font-weight: 700;
    transition: color, 0.3s ease;
}

.event-banner-profile-link a:hover {
    color: #FF5964;
    font-weight: 700;
    text-decoration: underline;
}

.attendees-modal {
    z-index: 10000;
}

.desc-field-label {
    color: #1D1D1D;
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
}

.attending-container {
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    margin-bottom: 2rem;
    /* margin-left: 2rem; */
    z-index: 9000;
}

.attending-container p {
    color: #1d1d1d;
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
}

.attendees-list {
    margin-top: .5rem;
}

.gm-style-iw-d p {
    color:black;
}

.attendee-circle img {
    width: 2.5rem; 
    height: 2.5rem; 
    border-radius: 50%;
}

.maybes-list {
    margin-top: .5rem;
}

.gm-style-mtc button {
    border-radius: 3.25rem !important;
}

.comments-container {
  display: flex;
  width: 100%;
  margin-top: 1rem;
}

.comments-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
}

.comments-header h2 {
  color: #1D1D1D;
}

.comments-header hr {
  margin: 1rem 0;
  opacity: 30%;
}

.comment-header {
  width: 100%;
  display: flex;
  padding: 1rem 0;
  gap: 1rem;
  display: flex;
  align-items: center;
}

.comment-header p {
  color: #1D1D1D;
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
}

.comment-body {
  background-color: white;
  width: 100%;
  height: auto;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 6px 2px 28px -5px rgba(0,0,0,0.2);
  -webkit-box-shadow: 6px 2px 28px -5px rgba(0,0,0,0.2);
  -moz-box-shadow: 6px 2px 28px -5px rgba(0,0,0,0.2);
}

.comment-body p {
  color: #1D1D1D;
}

.comment-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 0.5rem;
    gap: 0.5rem;
  }
  
  .comment-footer p {
    color: #1D1D1D;
    font-size: 0.75rem;
    opacity: 80%;
  }

.event-show-rsvp-buttons {
  padding: 1rem;
}

.comments-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comment-info {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
  }
  
  .events-edit-delete {
    display: flex;
    gap: 10px;
  }

.commentee-circle {
    display: inline-block;
    width: 2.5rem; 
    height: 2.5rem; 
    border-radius: 50%;
    background-color: #F4FFFD;
    margin-right: 1rem;
}

.commentee-circle:hover {
    transform: scale(1.2); 
    transition: transform 0.2s ease;
}

.commentee-circle img {
    width: 2.5rem; 
    height: 2.5rem; 
    border-radius: 50%;
}

.events-edit-delete p:hover {
    cursor: pointer;
}

.dropdown-arrow {
  color: #FF5964;
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
}

.comment-button {
  width: 10rem;
  height: 2;
}

.add-comment {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.add-comment textarea {
  width: 40rem;
  height: 10rem;
  padding: 0.5rem;
  padding-left: 2rem;
  padding-top: 2rem;
  background-color: white;
  color: #1D1D1D;
}

.comment-body textarea {
  width: 40rem;
  height: 10rem;
  padding: 0.5rem;
  padding-left: 2rem;
  padding-top: 2rem;
  background-color: white;
  color: #1D1D1D;
}