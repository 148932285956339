.auth-buttons {
  cursor: pointer;
  margin: 0rem 0rem;
  background: linear-gradient(145deg, rgba(250,130,76,1) 0%, rgba(255,89,100,1) 100%);
  transition: box-shadow 0.3s ease, color 0.3s ease;
  font-family: 'Lexend', sans-serif;
  margin-right: 0.5rem;
}

.nav-container {
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 100000;
  }

.navbar {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 1rem;
    width: 100%;
    height: 4rem;
    position: sticky;
    top: 0;
    z-index: 1000;
    /* background-color: #ff5964; */
    transition: background-color 0.3s;
    gap: 2rem;
}

#nav-auth {
    display: flex;
    align-items: center;
    /* width: 30%; */
    /* justify-self: flex-end; */
    margin-left: auto;
}

.nav-logo {
    justify-self: flex-start;
}

.logo {
    font-size: 2rem;
}

.discover {
    font-family: 'Lexend', sans-serif;
    transition: border-color 0.3s ease, color 0.3s ease;
    color: #F4FFFD;
}

.discover:hover {
    border-color: #89FC00;
    color: #89FC00;
}

.nav-links {
    display: flex;
    gap: 1rem;
    /* width: 30%; */
    justify-self: flex-start;
}


/*  */

