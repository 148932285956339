.signup-form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    width: 100%;
}

.signup-form-header {
    /* padding: 0.5rem; */
    margin-bottom: 1rem;
    white-space: nowrap;
}

.signup-form-header h2 {
    color: #F4FFFD;
    font-size: 2rem;
    text-align: center;
}

.signup-form-header h3 {
    color: #F4FFFD;
    text-align: center;
}

.signup-form p {
    font-size: 0.75rem;
    color: #F4FFFD;
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
}

.signup-form span {
    font-size: 1rem;
    color: #F4FFFD;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
}

.signup-form input::placeholder {
    color: #F4FFFD
}

.select-field, .select-field option {
    color: #F4FFFD; /* White color */
    height: 2.25rem;
    padding: 0;
    padding-inline-start: 0.5rem;
}


.select-field {
    background-color: transparent;
    border-style: solid;
    border-color: #F4FFFD;
    font-size: 1rem;
    width: 12rem;
    margin-top: 0.50rem;
}

.month {
    width: 8rem;
    border-radius: 3rem;
}

.date {
    width: 3.25rem;
    border-radius: 3rem;
}

.year {
    width: 5rem;
    border-radius: 3rem;
}

.field-row-container {
    display: flex;
    width: 100%;
    justify-content: center;
}


.gender-row-container {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 2.25rem;
}

.field-container {
    display: flex;
    flex-direction: column;
    margin: 0.25rem 0rem;
}


.gender-option {
    display: flex;
    gap: 0.25rem;
}

.sign-up-gender-field {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.signup-button {
    width: 10rem;
    height: 2rem;
    margin-top: 1rem;
    border-radius: 3rem;
    border-style: none;
    border-color: #F4FFFD;
    background: linear-gradient(145deg, rgba(250,130,76,1) 0%, rgba(255,89,100,1) 100%);
    border-width: 2px;
    color: #F4FFFD;
    transition: box-shadow 0.3s ease, color 0.3s ease;
    font-family: 'Lexend', sans-serif;
    padding: 0.5rem;
    cursor: pointer;
}


.gender-container {
    margin-bottom: 1rem;
}

.signup-form-content input[type='button'].signup-button:hover {
    background: linear-gradient(145deg, rgba(255,89,100,1) 0%, rgba(250,130,76,1) 100%);
    cursor: pointer;
    box-shadow: 6px 2px 28px -5px rgba(0,0,0,0.51);
    -webkit-box-shadow: 6px 2px 28px -5px rgba(0,0,0,0.51);
    -moz-box-shadow: 6px 2px 28px -5px rgba(0,0,0,0.51);
}

.primary-sport-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    justify-content: space-between;
    width: 35rem;
}

.sport-option-container {
    display: flex;
    width: 30%;
}

.sport-option-container button {
    width: 23rem;
    border-width: 2px;
    border-radius: 3rem;
    background-color: transparent;
    border-style: solid;
    border-color: #F4FFFD;
    padding: 0.25rem 0;
    transition: border-color 0.3s ease, color 0.3s ease;
}

button .sport-button-label {
    font-size: 0.75rem;
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    color: #F4FFFD;
    transition: color 0.3s ease;
}

.sport-option-container button:hover {
    border-color: aqua;
}

button .sport-button-label:hover {
    color: aqua;
}

.experience-level-radio-div {
    display: flex;
    gap: 1rem;    
}

.experience-level-radio-div label {
    width: 8rem;
}

.experience-container {
    display: flex;
    flex-direction: column;
    margin: 1.25rem 0;
}

.primary-sport-container label {
    color: #F4FFFD;
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    font-size: 0.75rem;
    margin: 0.25rem;
    padding: 0.25rem;
    padding-inline-start: 0.25rem;
    border-style: solid;
    border-width: 2px;
    border-color: #F4FFFD;
    border-radius: 3rem;
}


.secondary-sport-list-input-label {
    display: flex;
    align-items: center;
}

.secondary-sport-list-input-label input {
    margin: 0.25rem;
    padding: 0.25rem;
}

.sport-option-container .selected {
    border-color: aqua;
    background-color: lightblue;
}

.profile-picture-option img {
    height: 5rem;
    border-radius: 50%;
    margin: 1rem;
}

.profile-picture-container {
    display: flex;
    justify-content: center;
    width: 800px;
    height: 800px;
}

.profile-picture-option {
    max-width: 100px;
}

.profile-picture-radio {
  position: fixed;
  opacity: 0;
  pointer-events: none;
}

.profile-picture-container .selected img {
    border: 2px solid lightblue;
}