.discover-parent-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
  color: #F4FFFD;
}

.filter-container {
  width: 20%;
  padding: 1rem;
  /* border: #89fc00 2px solid; */
  border-radius: 3rem;
  margin: 1rem;
  margin-left: 2rem;
}

.index-container {
  height: 100%;
  min-width: 60%;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.index-header {
  margin-bottom: 1rem;
}

.filter-options {
  display: flex;
  flex-direction: column;
}

.sport-label {
  width: auto;
  background: linear-gradient(145deg, rgba(250, 130, 76, 1) 0%, rgba(255, 89, 100, 1) 100%);
  font-size: 0.75rem;
  padding: 0.75rem;
  border-radius: 3px;
  font-family: 'Lexend';
  font-weight: 500;
  border-radius: 2rem;
  transition: box-shadow 0.2s ease;
}

.sport-label:hover {
  cursor: pointer;
  box-shadow: 6px 2px 8px -5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 6px 2px 8px -5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 6px 2px 8px -5px rgba(0, 0, 0, 0.3);
}

.sport-filter-container::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

.sport-filter-label {
  width: auto;
  background: linear-gradient(145deg, #89fc00 0%, rgba(250, 130, 76, 1) 100%);
  font-size: 0.75rem;
  padding: 0.75rem;
  border-radius: 3px;
  font-family: 'Lexend';
  font-weight: 500;
  border-radius: 2rem;
  transition: box-shadow 0.3s ease-in-out;
}

.sport-filter-label:hover {
  cursor: pointer;
}



#event-create-button {
  width: 80%;
}

#event-create-button:hover {
  cursor: pointer;
}

.event-container {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem 0rem;
  min-width: 80%;
  max-width: 80%;
  border-radius: 1rem;
  transition: box-shadow 0.3s ease;
  gap: 2rem;
}

.event-container:hover {
  /* width: 80%; */
  /* height: 18.025rem; */
  box-shadow: 6px 6px 28px -5px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: 6px 6px 28px -5px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 6px 6px 28px -5px rgba(0, 0, 0, 0.51);
}

.event-content {
  width: 80%;
}

.event-header {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
}

.event-header h3 {
  font-size: 1.5rem;
}

.event-subheader {
  display: flex;
  align-items: center;
  gap: 0.5rem;

}

.event-subheader-difficulty {
  font-size: 0.75rem;
  font-family: 'Lexend';
  font-weight: 300;
  text-transform: uppercase;
}

.event-subheader-host {
  font-size: 0.75rem;
  font-family: 'Lexend';

}

.sport-icon {
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
  margin: 0.25rem 0rem;
  position: relative;
  /* Needed for absolute positioning of pseudo-element */
  z-index: 0;

  /* Bottom gradient layer */
}

.beginner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  /* Inherit the border radius */
  z-index: -1;
  background: linear-gradient(63deg, #5cb270 0%, #f4f269 100%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.intermediate::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  /* Inherit the border radius */
  z-index: -1;
  background: linear-gradient(145deg, #f7a2a1 0%, #f4d444 100%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.advanced::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  /* Inherit the border radius */
  z-index: -1;
  background: linear-gradient(63deg, #b94c98 0%, #f0073b 100%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.beginner {
  background: linear-gradient(63deg, #f4f269 0%, #5cb270 100%);
}

.intermediate {
  background: linear-gradient(63deg, #f4d444 0%, #f7a2a1 100%);
}

.advanced {
  background: linear-gradient(63deg, #f0073b 0%, #b94c98 100%);
}

.sport-icon:hover::before {
  opacity: 1;
}

.date-time-location {
  display: flex;
  gap: 1rem;
  margin: 0.50rem 0rem;
}

.date-time-location p {
  font-size: 0.75rem;
  font-family: 'Lexend';
  opacity: 0.75;
}

.event-description {
  margin: 1rem, 1rem, 1rem, 0rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  width: 80%;
}

.event-mini-map-container img {
  height: auto;
  width: 10rem;
  border-radius: 1rem;
  transition: box-shadow 0.3s ease;
}

.event-mini-map-container img:hover {
  box-shadow: 6px 6px 28px -5px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: 6px 6px 28px -5px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 6px 6px 28px -5px rgba(0, 0, 0, 0.51);
}

.filter-container {
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

.filter-sidebar-button {
  width: 8rem;
  height: 2rem;
  cursor: pointer;
  background: linear-gradient(145deg, rgba(250, 130, 76, 1) 0%, rgba(255, 89, 100, 1) 100%);
  transition: box-shadow 0.3s ease, color 0.3s ease;
  font-family: 'Lexend', sans-serif;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

.filter-sidebar-field-container {
  margin: 1rem 0rem;
}

.filter-sidebar-field-title {
  margin-bottom: 0.25rem;
}

.filter-sidebar-sport-options {
  display: flex;
  flex-direction: column;
  height: 18rem;
  overflow-y: scroll;
}

.filter-sidebar-sport-options::-webkit-scrollbar {
  width: 10px;
}

.filter-field {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.filter-field-option, .filter-sidebar-options {
  margin: 0.25rem;
}

.filter-field-option, .filter-sidebar-options label {
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
}

.filter-radio {
  position: fixed;
  opacity: 0;
  pointer-events: none;
}

.filter-label {
  cursor: pointer;
  margin: 0.25rem;
  opacity: 80%;
}

.filter-label input {
  position: fixed;
  opacity: 0;
  pointer-events: none;
}

.filter-label:hover {
  font-weight: 700;
  opacity: 100%;
}


.difficulty-field {
  display: flex;
  flex-direction: column;
}